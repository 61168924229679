import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from 'locales/en.json';
import es from 'locales/es.json';
import pt from 'locales/pt.json';

const resources = {
  en: {
    translation: en
  },
  es: {
    translation: es
  },
  pt: {
    translation: pt
  }
};

// Configuración de i18next
i18n
  //.use(HttpApi) // cargar traducciones a través de HTTP
  .use(LanguageDetector) // detectar el idioma del navegador
  .use(initReactI18next) // inicializar react-i18next
  .init({
    fallbackLng: 'es', // idioma por defecto
    detection: {
      order: [
        'queryString',
        'cookie',
        'localStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain'
      ],
      caches: ['cookie']
    },
    resources,
    react: {
      useSuspense: false // opcional, deshabilitar suspense
    }
  });
i18n.changeLanguage('es');
export default i18n;
