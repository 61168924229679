import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  driversFindAll,
  getStopsServices,
  postalCodesFinsAll,
  stopsFindAll,
  zonesCreate,
  zonesDelete,
  zonesFindAll,
  zonesUpdate
} from '../helpers/API/closerAPI';
import { AuthContext } from './AuthProvider';
import LoadingScreen from '../componentsGLS/LoadingScreen';
import { CreateZoneModes, MapStates } from '../dataGLS/CloserModes';
import {DefaultZoneData, ZoneDefaultID, zonesSort, zoneTypes} from '../dataGLS/zoneData';
import {
  GeometryTypes,
  polygonOptions,
  PolygonTypes
} from '../dataGLS/polygon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const MapProvider = ({ children }) => {
  const google = window.google;
  const [createZoneMode, setCreateZoneMode] = useState(
    CreateZoneModes.DISABLED
  );
  const [triggerClearZone, setTriggerClearZone] = useState(false);

  // To get a correct workflow we create zone "stages"
  // 1- To assign. Empty by default, set to assign. Once assigned set to next stage and clear it
  // 2- To print. Empty by default, set to print polygons in map. Once printed, set to next stage and clear it
  // 3- To show. Always has the current zones.
  const [zonesAssign, setZonesAssign] = useState([]);
  const [zonesPrint, setZonesPrint] = useState([]);
  const [zones, setZones] = useState([]);
  const zonesBackup = useRef(zones);
  const [shipmentAreas, setShipmentAreas] = useState([]);
  const [filterServices, setFilterServices] = useState([]);
  const [filterPostalCodes, setFilterPostalCodes] = useState([]);
  const [showStops, setShowStops] = useState(true);
  const [stops, setStops] = useState([]);
  const stopsFiltered = useMemo(() => {
    if (!stops) return null;
    if (!showStops) return [];
    return stops.filter(stop => {
      let show = true;
      if (filterServices.length > 0) {
        show =
          show &&
          !!filterServices.find(service => {
            return service.value === stop.service;
          });
      }
      if (filterPostalCodes.length > 0) {
        show =
          show &&
          !!filterPostalCodes.find(postalCode => {
            return postalCode.value === stop.address.postalCode;
          });
      }
      return show;
    });
  }, [stops, filterServices, filterPostalCodes, showStops]);
  const [centerArea, setCenterArea] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  const { date } = useContext(AuthContext);

  const [areaDownloaded, setAreaDownloaded] = useState(false);
  const [zonesDownloaded, setZonesDownloaded] = useState(false);
  const [stopsDownloaded, setStopsDownloaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [polygons, setPolygons] = useState([]);
  const polygonsBackup = useRef();
  const [zonesPrinted, setZonesPrinted] = useState(false);
  const [stopsAssigned, setStopsAssigned] = useState(false);
  const [selectedZone, setSelectedZone] = useState(null);
  const [canChangeToDataMap, setCanChangeToDataMap] = useState(false);
  const [triggerCancelEditZone, setTriggerCancelEditZone] = useState(false);
  const [mapState, setMapState] = useState(MapStates.DEFAULT);
  const [drivers, setDrivers] = useState([]);
  const [services, setServices] = useState([]);
  const [updatingZones, setUpdatingZones] = useState(false);
  const history = useRef({
    past: [],
    present: [],
    future: []
  });
  const updateData = async () => {
    console.log('Update data');
    clearZones();
    setZonesPrinted(false);
    setStopsAssigned(false);
    setStopsDownloaded(false);
    setZonesDownloaded(false);
    await getStops();
    void getZones();
  };

  useEffect(() => {
    console.log('New date', date);
    if (shipmentAreas.length === 0) return;
    console.log('New date updateData', date);
    void updateData();
  }, [date]);

  useEffect(() => {
    if (shipmentAreas.length === 0 || zonesPrinted) return;
    console.log('Updated shipmentAreas updateData');
    void updateData();
  }, [shipmentAreas]);

  useEffect(() => {
    if (shipmentAreas.length === 0) return;
    console.log('Assign Stops updatezones assign', { zonesAssign });
    assignStops();
  }, [zonesAssign]);

  const createZone = useCallback(() => {
    console.log('Create polygon');
    zonesBackup.current = zones;
    polygonsBackup.current = polygons;
    addZone({
      ...DefaultZoneData,
      priority: Math.max(...zones.map(z => z.priority)) + 1
    });
    setCreateZoneMode(CreateZoneModes.MAP);
    history.current = {
      past: [],
      present: [],
      future: []
    };
  }, [zones, polygons]);

  const saveZone = useCallback(
    zone => {
      if (!zone) return;
      console.log('Save zone', zone);
      setUpdatingZones(true);
      if (zone.id === ZoneDefaultID) {
        void sendNewZone(zone);
      } else {
        void updateZone(zone);
      }
    },
    [filterServices, filterPostalCodes, zones]
  );

  const sendNewZone = async zone => {
    const data = {
      type: zone.type,
      name: zone.name,
      info: zone.info,
      status: zone.status,
      geometry: zone.geometry,
      thresholds: zone.thresholds,
      driver: zone.driver,
      services: zone.services,
      postalCodes: zone.postalCodes
    };
    if (zone.type === zoneTypes.TEMPORARY) {
      data['temporary'] = {
        dateRange: zone.temporary.dateRange
      };
    }
    console.log('Send new zone', { zone, data });

    try {
      const response = await zonesCreate(data);
      const newZones = zones.map(zone => {
        return zone.id === ZoneDefaultID ? response : zone;
      });
      setPolygons(prevVal =>
        prevVal.map(polygonData => {
          if (polygonData.id.startsWith(ZoneDefaultID)) {
            return {
              ...polygonData,
              id: polygonData.id.replace(ZoneDefaultID, response.id)
            };
          } else {
            return polygonData;
          }
        })
      );
      console.log('NewZones set zones', newZones);
      setSelectedZone(null);
      setStopsAssigned(false);
      resetFilters();
      zonesBackup.current = [];
      polygonsBackup.current = [];
      setCreateZoneMode(CreateZoneModes.DISABLED);
      setZonesAssign(newZones);
    } catch (e) {
      console.error(e);
    }
    setUpdatingZones(false);
  };

  const updateZone = async zone => {
    console.log('Update zone', { selectedZone, zone });
    try {
      await zonesUpdate(zone.id, zone);
      console.log('Set zone on update zone');
      setSelectedZone(null);
      setStopsAssigned(false);
      resetFilters();
      zonesBackup.current = [];
      polygonsBackup.current = [];
      setCreateZoneMode(CreateZoneModes.DISABLED);
      setZonesAssign(zones.map(z => (z.id === zone.id ? zone : z)));
    } catch (e) {
      console.error(e);
    }
    setUpdatingZones(false);
  };

  const updatePriorityZone = async zone => {
    console.log('Update zone', { selectedZone, zone });
    try {
      await zonesUpdate(zone.id, zone, ['priority']);
      console.log('Set zone on update zone');
      setStopsAssigned(false);
      setZonesAssign(zones.map(z => (z.id === zone.id ? zone : z)));
    } catch (e) {
      console.error(e);
    }
    setUpdatingZones(false);
  };
  const updateStatusZone = useCallback(async zone => {
    console.log('Update zone', zone);
    try {
      setStopsAssigned(false);
      await zonesUpdate(zone.id, zone);
      console.log('MapProvider updateStatus setZones');
      setZonesAssign(
        zones.filter(z => (z.id === zone.id ? zone : z))
      );
      zonesBackup.current = [];
      polygonsBackup.current = [];
      setCreateZoneMode(CreateZoneModes.DISABLED);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const clearZones = useCallback(() => {
    console.log('set zones empty Clear polygon');
    setTriggerClearZone(val => !val);
    console.log('MapProvider clearzones setZones');
    setZones([]);
  }, []);

  const getCenterBounds = bounds => {
    return {
      lat: (bounds.south + bounds.north) / 2,
      lng: (bounds.east + bounds.west) / 2
    };
  };

  const latLngBounds = areas => {
    if (areas.length === 0) return { lat: 41.85, lng: -87.65 };
    let minLat = areas[0].centroid.coordinates.lat;
    let minLng = areas[0].centroid.coordinates.lng;
    let maxLat = areas[0].centroid.coordinates.lat;
    let maxLng = areas[0].centroid.coordinates.lng;
    areas.forEach(area => {
      if (area.centroid.coordinates.lat < minLat)
        minLat = area.centroid.coordinates.lat;
      if (area.centroid.coordinates.lng < minLng)
        minLng = area.centroid.coordinates.lng;
      if (area.centroid.coordinates.lat > maxLat)
        maxLat = area.centroid.coordinates.lat;
      if (area.centroid.coordinates.lng > maxLng)
        maxLng = area.centroid.coordinates.lng;
    });

    return {
      north: maxLat,
      south: minLat,
      east: maxLng,
      west: minLng
    };
  };
  const getLatLngBounds = coordinates => {
    let minLat = coordinates[0].lat;
    let minLng = coordinates[0].lng;
    let maxLat = coordinates[0].lat;
    let maxLng = coordinates[0].lng;

    coordinates.forEach(coordinate => {
      if (coordinate.lat < minLat) minLat = coordinate.lat;
      if (coordinate.lng < minLng) minLng = coordinate.lng;
      if (coordinate.lat > maxLat) maxLat = coordinate.lat;
      if (coordinate.lng > maxLng) maxLng = coordinate.lng;
    });

    return { minLat, minLng, maxLat, maxLng };
  };

  const latLngBoundsZone = zone => {
    let bounds = { minLat: 0, minLng: 0, maxLat: 0, maxLng: 0 };

    switch (zone.geometry.type) {
      case GeometryTypes.Polygon:
        bounds = getLatLngBounds(zone.geometry.coordinates[0]);
        break;
      case GeometryTypes.MultiPolygon:
        zone.geometry.coordinates.forEach((polygon, index) => {
          const polygonBounds = getLatLngBounds(polygon[0]);
          if (index === 0) {
            bounds = polygonBounds;
          } else {
            bounds.minLat = Math.min(bounds.minLat, polygonBounds.minLat);
            bounds.minLng = Math.min(bounds.minLng, polygonBounds.minLng);
            bounds.maxLat = Math.max(bounds.maxLat, polygonBounds.maxLat);
            bounds.maxLng = Math.max(bounds.maxLng, polygonBounds.maxLng);
          }
        });
        break;
    }
    return {
      north: bounds.maxLat,
      south: bounds.minLat,
      east: bounds.maxLng,
      west: bounds.minLng
    };
  };

  const getShipmentAreas = async () => {
    try {
      const postalCodes = await postalCodesFinsAll();
      console.log('postalCodes', postalCodes);
      setShipmentAreas(postalCodes);
      const bounds = latLngBounds(postalCodes);
      setCenterArea(getCenterBounds(bounds));
      setMapBounds(bounds);
    } catch (e) {
      console.error(e);
    }
    setAreaDownloaded(true);
  };

  const getDrivers = async () => {
    try {
      const d = await driversFindAll();
      console.log('All drivers', d);
      setDrivers(d);
    } catch (e) {
      console.error(e);
    }
  };
  const getServices = async () => {
    try {
      const s = await getStopsServices();
      console.log('All services', s);
      setServices(s);
    } catch (e) {
      console.error(e);
    }
  };

  const centerZone = useCallback(zone => {
    console.log('Center to zone', zone);
    if (!zone.geometry || !zone.geometry.coordinates) return;
    const bounds = latLngBoundsZone(zone);
    setMapBounds(bounds);
  }, []);

  const getZones = async () => {
    try {
      const zones = await zonesFindAll();
      if (zones.length === 0) {
        setStopsAssigned(true);
        setZonesPrinted(true);
        setZones(zones);
      } else {
        setZonesPrint(zones);
      }
    } catch (e) {
      console.error(e);
    }
    setZonesDownloaded(true);
  };
  useEffect(() => {
    console.log('mapPRovider zonesPrint', zonesPrint);
  }, [zonesPrint]);
  useEffect(() => {
    console.log('mapPRovider zones', zones);
    console.log('zones assigned zone mode:', {
      createZoneMode,
      canChangeToDataMap
    });
    if (canChangeToDataMap && createZoneMode === CreateZoneModes.MAP) {
      setCreateZoneMode(CreateZoneModes.DATA);
      setCanChangeToDataMap(false);
    }
  }, [zones]);

  const getStops = async () => {
    try {
      const stops = await stopsFindAll(date);
      console.log('Stops', stops);
      setStops(stops);
    } catch (e) {
      /* empty */
    }
    setStopsDownloaded(true);
  };

  const assignStops = useCallback(() => {
    console.log('AssignStops', {zonesAssign});
    // For every stop find polygon for every zone. IF stops is inside, assign if not assigned to a zone, assign to postal code
    const assignedZones = zonesAssign.map(zone => {
      return { ...zone, stops: [] };
    });
    console.log('AssignStops', { assignedZones });
    assignedZones.sort(zonesSort);
    console.log('Sorted zones', JSON.parse(JSON.stringify(assignedZones)));
    console.log('Assign zones polygons', polygons);
    console.log('Assign zones polygons length', polygons.length);
    const assignedAreas = shipmentAreas.map(area => {
      return { ...area, stops: [] };
    });
    // Check for each stop on which zone assign
    // if there is no zone, assign to area
    stops.forEach(stop => {
      for (let i = 0; i < assignedZones.length; i++) {
        const zone = assignedZones[i];

        //Check if stop meet filters criteria
        let filtered =
          // IF zone has services filter and stop service is not in it, not meet criteria
          !(
            zone.services?.length > 0 && !zone.services.includes(stop.service)
          ) &&
          // IF zone has postal codes filter and stop postal code is not in it, not meet criteria
          !(
            zone.postalCodes?.length > 0 &&
            !zone.postalCodes.some(
              postalCode =>
                postalCode.code === stop.address.postalCode &&
                postalCode.country === stop.address.country
            )
          );

        // If pass filters, check if location is inside polygons
        if (filtered) {
          //MULTIPOLYGONS
          const polygonsZone = polygons.filter(polygon => {
            return polygon.id.startsWith(zone.id);
          });
          if (polygonsZone.length > 0) {
            for (let j = 0; j < polygonsZone.length; j++) {
              const polygon = polygonsZone[j];
              const inside = google.maps.geometry.poly.containsLocation(
                stop.address.location.coordinates,
                polygon.polygon
              );
              if (inside) {
                if (zone.stops) {
                  zone.stops.push(stop);
                } else {
                  zone.stops = [stop];
                }
                assignedZones[i] = zone;
                return;
              }
            }
          }
          //POLYGONS
          const polygon = polygons.find(polygon => {
            return polygon.id.startsWith(zone.id);
          });
          if (!polygon) continue;
          const inside = google.maps.geometry.poly.containsLocation(
            stop.address.location.coordinates,
            polygon.polygon
          );
          if (inside) {
            if (zone.stops) {
              zone.stops.push(stop);
            } else {
              zone.stops = [stop];
            }
            assignedZones[i] = zone;
            return;
          }
        }
      }
      if (assignedAreas[0].stops) {
        assignedAreas[0].stops.push(stop);
      } else {
        assignedAreas[0].stops = [stop];
      }
    });
    console.log('assignedZones', assignedZones);

    const coloredPolygons = polygons;
    assignedZones.forEach(zone => {
      if (coloredPolygons) {
        const polygonIndexes = coloredPolygons.reduce(
          (list, polygon, index) => {
            if (polygon.id.startsWith(zone.id)) list.push(index);
            return list;
          },
          []
        );
        if (polygonIndexes.length > 0) {
          polygonIndexes.forEach(polygonIndex => {
            const polygon = polygons[polygonIndex];
            if (!zone.thresholds || !zone.thresholds.stops) {
              zone.thresholds = {
                stops: ['', ``]
              };
            }
            /*if (zone.id === ZoneDefaultID) {
              polygon.polygon.setOptions({
                fillColor: 'black',
                strokeColor: 'black'
              });
              zone.loadColor = 'success';
            } else */ if (zone.stops.length < zone.thresholds.stops[0]) {
              polygon.polygon.setOptions({
                fillColor: 'blue',
                strokeColor: 'blue'
              });
              zone.loadColor = 'primary';
            } else if (zone.stops.length > zone.thresholds.stops[1]) {
              polygon.polygon.setOptions({
                fillColor: 'red',
                strokeColor: 'red'
              });
              zone.loadColor = 'danger';
            } else {
              polygon.polygon.setOptions({
                fillColor: 'green',
                strokeColor: 'green'
              });
              zone.loadColor = 'success';
            }
            coloredPolygons[polygonIndex] = polygon;
          });
        }
      }
    });
    console.log('Set zones assigned', { assignedZones, coloredPolygons });
    setZonesPrint(assignedZones);
    setShipmentAreas(assignedAreas);
    setStopsAssigned(true);
    setPolygons(coloredPolygons);
  }, [zonesAssign, polygons, stops]);

  useEffect(() => {
    console.log('createZoneMode', createZoneMode);
  }, [createZoneMode]);

  useEffect(() => {
    const newV =
      !(
        areaDownloaded &&
        zonesDownloaded &&
        stopsDownloaded &&
        zonesPrinted &&
        stopsAssigned
      ) || updatingZones;
    console.log('Loading', {
      areaDownloaded,
      zonesDownloaded,
      stopsDownloaded,
      zonesPrinted,
      stopsAssigned,
      updatingZones,
      newV
    });
    setLoading(newV);
  }, [
    areaDownloaded,
    zonesDownloaded,
    stopsDownloaded,
    zonesPrinted,
    stopsAssigned,
    updatingZones
  ]);

  useEffect(() => {
    void getShipmentAreas();
    void getDrivers();
    void getServices();
  }, []);

  useEffect(() => {
    console.log('zones', { zones, createZoneMode });
    if (createZoneMode === CreateZoneModes.MAP && !selectedZone) {
      setSelectedZone(zones.find(zone => zone.id === ZoneDefaultID));
    }
    setUpdatingZones(false);
  }, [zones]);

  useEffect(() => {
    console.log('zonesPrinted', zonesPrinted);
  }, [zonesPrinted]);

  const addZone = useCallback(
    zoneData => {
      const zone = {
        ...zoneData,
        type: zoneTypes.FIXED,
        name: '',
        info: '',
        status: {
          code: 'enabled'
        },
        thresholds: {
          stops: ['', '']
        }
      };
      //setStopsAssigned(val => !val);
      console.log('Set zones addZone');
      setZonesPrint([zone, ...zones]);
    },
    [zones]
  );

  useEffect(() => {
    console.log('MapProvider selectedZone useEffect', { selectedZone });
    if (!selectedZone) {
      setPolygons(prevPolygons =>
        prevPolygons.map(polygon => {
          polygon.polygon.setOptions({
            strokeWeight: polygonOptions.strokeWeight,
            fillOpacity: polygonOptions.fillOpacity,
            clickable: false
          });
          return polygon;
        })
      );
      return;
    }
    setPolygons(prevPolygons =>
      prevPolygons.map(polygon => {
        if (polygon.id.startsWith(selectedZone.id)) {
          polygon.polygon.setOptions({
            strokeWeight: 4,
            fillOpacity: 0.8,
            clickable: true
          });
        } else {
          polygon.polygon.setOptions({
            strokeWeight: polygonOptions.strokeWeight,
            fillOpacity: 0.2,
            clickable: false
          });
        }
        return polygon;
      })
    );
    console.log('Pre assign stops', { selectedZone, createZoneMode });

    if (
      selectedZone?.geometry?.coordinates.length === 0 &&
      createZoneMode === CreateZoneModes.MAP
    )
      return;
    const newZones = zones.map(z =>
      z.id === selectedZone.id ? selectedZone : z
    );
    setZonesAssign(newZones);
  }, [selectedZone]);

  useEffect(() => {
    console.log("showStops", showStops);
  }, [showStops]);

  const addPolygon = useCallback(path => {
    console.log('Add polygon');
    setSelectedZone(zoneUpdate => {
      const geometry = zoneUpdate.geometry || {
        coordinates: [],
        type: GeometryTypes.MultiPolygon
      };
      return {
        ...zoneUpdate,
        geometry: {
          ...geometry,
          type: GeometryTypes.MultiPolygon,
          coordinates: [...geometry?.coordinates, path]
        }
      };
    });
    setCanChangeToDataMap(true);
  }, []);

  const removePolygon = useCallback(polygon => {
    setSelectedZone(zoneUpdate => {
      console.log(zoneUpdate);
      const coordinates = polygons.filter(poly => {
        if (poly.id.startsWith(zoneUpdate.id)) {
          console.log('Delete Start with', { poly, polygon, zoneUpdate });
          if (polygon.id !== poly.id) {
            console.log('Delete Different id', { poly, polygon, zoneUpdate });
            return true;
          }
          console.log('Delete same id', { poly, polygon, zoneUpdate });
        }
        return false;
      });
      return {
        ...zoneUpdate,
        geometry: {
          ...zoneUpdate.geometry,
          type: GeometryTypes.MultiPolygon,
          coordinates
        }
      };
    });
  }, []);

  /*
    Cancel actions inside a trigger to show loading page to user
   */
  const cancelEditZone = useCallback(() => {
    console.log('MapProvider cancelEdit setZones');
    setUpdatingZones(true);
    setLoading(true);
    setTriggerCancelEditZone(prevVal => !prevVal);
  }, []);

  useEffect(() => {
    if (createZoneMode === CreateZoneModes.DISABLED) return;
    console.log('cancelEdit trigger');
    setZonesPrint(zonesBackup.current);
    zonesBackup.current = [];
    polygons.forEach(polygonData => {
      if (polygonData.type === PolygonTypes.Zone && polygonData.polygon) {
        polygonData.polygon.setMap(null);
      }
    });
    setPolygons(polygonsBackup.current);
    polygonsBackup.current = [];
    setSelectedZone(null);
    setCreateZoneMode(CreateZoneModes.DISABLED);
    resetFilters();
    setMapState(MapStates.DEFAULT);
  }, [triggerCancelEditZone]);

  const resetFilters = () => {
    setFilterServices([]);
    setFilterPostalCodes([]);
  };

  const deleteZone = useCallback(
    async zone => {
      setLoading(true);
      setUpdatingZones(true);
      try {
        await zonesDelete(zone.code);
        console.log('Set zones on delete');
        const newZones = zones.filter(z => z.id !== zone.id);
        //Hide all polygon zones
        polygons.forEach(polygonData => {
          if (polygonData.type === PolygonTypes.Zone && polygonData.polygon) {
            polygonData.polygon.setMap(null);
          }
        });
        setPolygons(prevValue =>
          prevValue.filter(polygonData => !polygonData.id.startsWith(zone.id))
        );
        if (newZones.length > 0) {
          setStopsAssigned(false);
          setZonesAssign(newZones);
        } else {
          setZones(newZones);
        }
      } catch (e) {
        //Zone not exists, delete it
        console.error('status error', { status: e.status });
        if (e.status === 404) {
          console.log('status error updateData');
          void updateData();
        }
        console.error('Error deleting zone');
        console.error(e);
      }
      setUpdatingZones(false);
    },
    [polygons, zones]
  );
  useEffect(() => {
    console.log('Filter stops Markers', stopsFiltered);
  }, [stopsFiltered]);

  const hideAllPolygons = useCallback(() => {
    history.current.present.forEach(polygonData => {
      console.log('Hide polygon', polygonData);
      if (polygonData.type === PolygonTypes.Zone && polygonData.polygon) {
        polygonData.polygon.setMap(null);
      }
    });
  }, []);

  const historyUndo = useCallback(() => {
    console.log('History Undo', history.current);
    const last = history.current.past.slice(-1)[0];
    if (!last) return false;
    hideAllPolygons();
    history.current = {
      past: [...history.current.past].slice(0, -1),
      present: last,
      future: history.current.present
        ? [...history.current.future, history.current.present]
        : history.current.future
    };
    console.log('History Undo', history.current);
    setPolygons(last);
    return true;
  }, []);

  const historyRedo = useCallback(() => {
    const next = history.current.future.slice(-1)[0];
    if (!next) return false;
    hideAllPolygons();
    history.current.present.forEach(polygonData => {
      console.log('Hide polygon', polygonData);
      if (polygonData.type === PolygonTypes.Zone && polygonData.polygon) {
        polygonData.polygon.setMap(null);
      }
    });
    history.current = {
      past: history.current.present
        ? [...history.current.past, history.current.present]
        : history.current.past,
      present: next,
      future: [...history.current.future].slice(0, -1)
    };
    console.log('History Redo', history.current);
    setPolygons(next);
    return true;
  }, []);

  const historySetPresent = useCallback(present => {
    history.current = {
      past: [...history.current.past, history.current.present],
      present,
      future: []
    };

    console.log('History set present', history.current);
  }, []);

  useEffect(() => {
    console.log('Change loading', { loading });
  }, [loading]);

  useEffect(() => {
    console.log('Filters', { filterServices, filterPostalCodes });
  }, [filterServices, filterPostalCodes]);

  return (
    <MapContext.Provider
      value={{
        createZone,
        triggerClearZone,
        clearZones,
        zonesAssign,
        setZonesAssign,
        zonesPrint,
        setZonesPrint,
        zones,
        setZones,
        zonesBackup,
        showStops,
        setShowStops,
        stops,
        setStops,
        shipmentAreas,
        setShipmentAreas,
        polygons,
        setPolygons,
        polygonsBackup,
        centerArea,
        zonesDownloaded,
        addZone,
        addPolygon,
        zonesPrinted,
        setZonesPrinted,
        createZoneMode,
        setCreateZoneMode,
        selectedZone,
        setSelectedZone,
        cancelEditZone,
        saveZone,
        stopsAssigned,
        setStopsAssigned,
        centerZone,
        mapBounds,
        deleteZone,
        updateZone,
        updatePriorityZone,
        updateStatusZone,
        setUpdatingZones,
        history,
        removePolygon,
        historyUndo,
        historyRedo,
        historySetPresent,
        mapState,
        setMapState,
        drivers,
        services,
        filterServices,
        setFilterServices,
        filterPostalCodes,
        setFilterPostalCodes,
        stopsFiltered
      }}
    >
      {children}
      {loading && <LoadingScreen />}
    </MapContext.Provider>
  );
};

export default withTranslation()(MapProvider);

MapProvider.propTypes = {
  t: PropTypes.func,
  children: PropTypes.any
};
export const MapContext = createContext();
