import { getFPEType } from './utilsECC';

export const resetZoneData = newData => {
  newData.stopsNumber = 0;
  newData.packages = 0;
  newData.volume = 0;
  newData.weight = 0;
  newData.FPEPassed = 0;
  newData.FPEToday = 0;
  newData.FPEFuture = 0;
  newData.assignedTo = '';
  return newData;
};
export const zoneCalcData = zone => {
  let newData = {
    loadColor: '',
    loadText: '',
    stopsNumber: '',
    packages: '',
    volume: '',
    FPEPassed: '',
    FPEToday: '',
    FPEFuture: '',
    name: '',
    type: '',
    stopsThresholds: ['', '']
  };
  if (zone) {
    newData = resetZoneData(newData);
    if (zone.thresholds && zone.thresholds.stops) {
      newData.stopsThresholds = zone.thresholds.stops;
    } else {
      newData.stopsThresholds = ['', ''];
    }

    if (zone.stops) {
      newData.stopsNumber = zone.stops.length;
      zone.stops.forEach(stop => {
        newData.packages += stop.packageCount || 0;
        newData.weight += stop.weight || 0;
        newData.volume += stop.size || 0;
        switch (getFPEType(stop.estimatedDeliveryDate)) {
          case -1:
            newData.FPEPassed += 1;
            break;
          case 0:
            newData.FPEToday += 1;
            break;
          case 1:
            newData.FPEFuture += 1;
            break;
        }
      });
    }
  }
  return newData;
};
