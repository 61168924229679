import { AdvancedMarker, Pin, useMap } from '@vis.gl/react-google-maps';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import StopMarker from './StopMarker';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import {ClusterRenderer} from './ClusterRenderer';

const StopsMarkers = props => {
  const { stops } = props;

  const [markers, setMarkers] = useState([]);
  const map = useMap();
  const clusterer = useMemo(() => {
    if (!map) return null;
    //return new MarkerClusterer({ map });
    return new MarkerClusterer({
      map,
      renderer: new ClusterRenderer()
    });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;
    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  const setMarkerRef = useCallback((marker, key) => {
    setMarkers(markers => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        return {...markers, [key]: marker};
      } else {
        const {[key]: _, ...newMarkers} = markers;

        return newMarkers;
      }
    });
  }, []);

  return (
    <>
      {stops.map(stop => (
        <StopMarker key={stop.id} stop={stop} setMarkerRef={setMarkerRef} />
      ))}
    </>
  );
};

StopsMarkers.propTypes = {
  t: PropTypes.func,
  stops: PropTypes.array,
  markers: PropTypes.any,
  setMarkers: PropTypes.func
};

export default withTranslation()(StopsMarkers);
