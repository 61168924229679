import React from 'react';
import { Button, CloseButton, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

function CloserModal(props) {
  const {
    t,
    header = '',
    body = '',
    buttonLabel = '',
    callback = () => {},
    buttonIcon = '',
    buttonVariant = 'primary',
    show = false,
    setShow,
    showCancelButton = true
  } = props;
  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className={'border-0 p-4'}>
          <Modal.Title className={'fw-bold'}>{header}</Modal.Title>
          <CloseButton
            className="btn p-2 fs-12 btn-sm transition-base pe-3"
            onClick={() => setShow(false)}
          />
        </Modal.Header>
        <Modal.Body className={'py-0 px-4'}>{body}</Modal.Body>
        <Modal.Footer
          className={`border-0 ${
            showCancelButton ? 'justify-content-between puta' : ''
          } px-4 pb-4`}
        >
          {showCancelButton && (
            <Button
              variant={'outline-primary'}
              className={'rounded-pill border-0 fw-bold'}
              onClick={() => setShow(false)}
            >
              {t('Cancel')}
            </Button>
          )}
          <Button
            variant={buttonVariant}
            onClick={() => {
              setShow(false);
              callback();
            }}
            className={'rounded-pill'}
          >
            {buttonIcon ? (
              <span className="material-symbols-outlined me-2">
                {buttonIcon}
              </span>
            ) : (
              ''
            )}
            {buttonLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

CloserModal.propTypes = {
  t: PropTypes.func,
  header: PropTypes.string,
  body: PropTypes.any,
  buttonLabel: PropTypes.string,
  callback: PropTypes.func,
  buttonIcon: PropTypes.string,
  buttonVariant: PropTypes.string,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  showCancelButton: PropTypes.bool
};

export default withTranslation()(CloserModal);
