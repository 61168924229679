import React, {useContext, useState} from 'react';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import { Nav } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
//import en from 'date-fns/locale/en';
import pt from 'date-fns/locale/pt';
import {AuthContext} from '../../../providers/AuthProvider';

const TopNavRightSideNavItem = props => {
  const { t, i18n } = props;
  registerLocale('es', es);
  registerLocale('pt', pt);
  const {date, setDate} = useContext(AuthContext);

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >{/*}
      <li className={'text-light me-2'}>{t('Showing FPE data:')}</li>
      <li className={'text-light me-2'}>{i18n.language}</li>
      <li>
        <DatePicker
          popperPlacement="bottom-start"
          showIcon
          icon={
            <span className="material-symbols-outlined end-0 fs-8">
              calendar_today
            </span>
          }
          locale={i18n.language}
          dateFormat="dd-MM-yyyy"
          selected={date}
          onChange={date => setDate(date)}
          formatWeekDay={day => day.slice(0, 3)}
          className="form-control ps-3 pe-0"
          placeholderText={t('Select Date')}
        />
      </li>
      */}
      <ProfileDropdown />
    </Nav>
  );
};

export default withTranslation()(TopNavRightSideNavItem);
