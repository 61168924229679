import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const CounterZone = ({ value, name }) => {
  const printValue = () => {
    //console.log("PrintValue", {name, value});
    if (value === undefined || value === null) return '0';
    try {
      const temp = parseFloat(value);
      return temp.toLocaleString('de-DE', { maximumFractionDigits: 2 });
    } catch (e) {
      return value;
    }
  };

  return (
    <div className={'d-flex p-2 bg-hover-gray'}>
      <p className={'flex-fill'}>{name}</p>
      <p>{printValue()}</p>
    </div>
  );
};

CounterZone.propTypes = {
  t: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string
};

export default withTranslation()(CounterZone);
