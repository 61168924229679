import React from 'react';
import { components } from 'react-select';

export const SelectStylesGLS = {
  control: styles => ({ ...styles, backgroundColor: 'white', minWidth: '200px' }),
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: 'var(--gls-surface-light-2)',
      border: '1px solid var(--gls-light-2)',
      borderRadius: 'var(--gls-border-radius-pill)',
      alignItems: 'center',
      paddingRight: '5px',
      color: '#5A5555'
    };
  },
  multiValueLabel: styles => ({
    ...styles,
    color: '#5A5555'
  }),
  multiValueRemove: styles => ({
    ...styles,
    height: 'fit-content',
    padding: '0px'
  }),
  valueRemove: styles => ({
    ...styles,
    border: '1px solid var(--gls-light-2)',
    borderRadius: 'var(--gls-border-radius-pill)',
    height: 'fit-content',
    padding: '0px',
    color: '#5A5555'
  }),
  valueContainer: styles => ({
    ...styles,
    paddingTop: '5px',
    paddingBottom: '5px',
    maxHeight: '75px',
    overflow: 'overlay'
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none'
  })
};

export const DropdownIndicatorGLS = props => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="material-symbols-outlined text-black">
        arrow_drop_down
      </span>
    </components.DropdownIndicator>
  );
};
export const ClearIndicatorGLS = props => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps }
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <span className="material-symbols-outlined text-black fs-10">close</span>
    </div>
  );
};

export const MultiValueRemoveGLS = props => {
  return (
    <components.MultiValueRemove {...props}>
      <span className="material-symbols-outlined text-red fs-9">cancel</span>
    </components.MultiValueRemove>
  );
};

export const SelectComponentsGLS = { DropdownIndicator: DropdownIndicatorGLS, ClearIndicator: ClearIndicatorGLS, MultiValueRemove: MultiValueRemoveGLS};
