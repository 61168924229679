// Calculate cross product between three points
const crossProduct = (p1, p2, p3) => {
  return (
    (p2.lng - p1.lng) * (p3.lat - p1.lat) -
    (p2.lat - p1.lat) * (p3.lng - p1.lng)
  );
};

//Verify if two segments intersects
const doSegmentsIntersect = (p1, p2, p3, p4) => {
  //Calculate cross productos to determine point orientation
  const d1 = crossProduct(p3, p4, p1);
  const d2 = crossProduct(p3, p4, p2);
  const d3 = crossProduct(p1, p2, p3);
  const d4 = crossProduct(p1, p2, p4);

  //Check if orientations are different
  return d1 * d2 < 0 && d3 * d4 < 0;
  // True segments intersect
  // False segments DON'T intersect
};

//Detect if a polygon has self intersections
export const hasOverlappingLines = polygon => {
  const n = polygon.length;

  //Iterate on every pair of segments
  for (let i = 0; i < n; i++) {
    const p1 = polygon[i];
    const p2 = polygon[(i + 1) % n];

    // Comparar este segmento con todos los demás, excluyendo adyacentes
    // Compare this segment with the others, excluding adjacent
    for (let j = i + 2; j < n; j++) {
      // Saltar los segmentos adyacentes (que comparten un vértice)
      //Skip adjacent segments (one vertex in common)
      if (j === (i + 1) % n) continue;

      const p3 = polygon[j];
      const p4 = polygon[(j + 1) % n];

      //Verify if segments (p1, p2) and (p3, p4) intersects
      if (doSegmentsIntersect(p1, p2, p3, p4)) {
        return true; // There is an intersection
      }
    }
  }

  return false; // There aren't intersection
};

// Calculate polygon's area. A positive area for counter-clockwise direction path
const calculateArea = points => {
  let area = 0;
  for (let i = 0; i < points.length; i++) {
    let j = (i + 1) % points.length;
    area += (points[j].lng - points[i].lng) * (points[j].lat + points[i].lat);
  }
  return area / 2;
};

//Reorders points to set second+ polygons in oposite direction to first
export const reorderPolygonPointsHole = arr => {
  if (arr.length <= 1) return arr;
  // Set first polygon direction
  const outerDirection = calculateArea(arr[0]) < 0; // true for counter-clockwise, false for clockwise

  return arr.map((subArray, index) => {
    if (index === 0) {
      return subArray; // Maintain first.
    }
    // For holes, invert order if direction is the same as first polygon
    const innerDirection = calculateArea(subArray) < 0;
    return innerDirection === outerDirection ? subArray.reverse() : subArray;
  });
};
