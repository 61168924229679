import { APIClient, setAuthorization } from './apiHelper';
import { setItemToStore } from '../utils';
import moment from 'moment';
import { GeometryTypes } from '../../dataGLS/polygon';

const apiClient = new APIClient();

export const login = async token => {
  const response = await apiClient.post('/user/login/jwt', {
    login: {
      token
    }
  });
  const user = response.data.user;
  //setItemToStore('user', JSON.stringify(user));
  const accesstoken = response.headers['x-access-token'];
  setItemToStore('token', accesstoken);
  setAuthorization(accesstoken);
  return user;
};

export const zonesFindAll = async () => {
  const response = await apiClient.post('/user/zones/find', {
    find: {
      query: 'all'
    }
  });
  if (!response.data) return response;
  const zones = response.data.found;
  return zones.map(zone => {
    return coordinatesZoneArrayToObject(zone);
  });
};

export const postalCodesFinsAll = async () => {
  const response = await apiClient.post('/user/postalCodes/find', {
    find: {
      query: 'all'
    }
  });
  if (!response.data) return response;
  const postalCodes = response.data.found;
  return postalCodes.map(postalCode => {
    const formattedCoordinates = postalCode.polygon.coordinates.map(
      coordinate => {
        if (postalCode.polygon.type === GeometryTypes.Polygon) {
          return coordinate.map(c => {
            return { lat: c[1], lng: c[0] };
          });
        }
        if (postalCode.polygon.type === GeometryTypes.MultiPolygon) {
          return coordinate.map(polygon => {
            return polygon.map(c => {
              return { lat: c[1], lng: c[0] };
            });
          });
        }
      }
    );
    return {
      ...postalCode,
      centroid: {
        ...postalCode.centroid,
        coordinates: {
          lat: postalCode.centroid.coordinates[1],
          lng: postalCode.centroid.coordinates[0]
        }
      },
      polygon: {
        ...postalCode.polygon,
        coordinates: formattedCoordinates
      }
    };
  });
};

export const stopsFindAll = async date => {
  return [];
  /*const response = await apiClient.post('/user/stops/load', {
    load: {
      //date
      date: moment(date).format('YYYY-MM-DD')
    }
  });
  if (!response.data) return response;
  let stops = response.data.results;
  return stops.map((stop, index) => {
    return {
      ...stop,
      id: `stop-${stop.date}-${index}`,
      address: {
        ...stop.address,
        location: {
          ...stop.address.location,
          coordinates: {
            lat: stop.address.location.coordinates[1],
            lng: stop.address.location.coordinates[0]
          }
        }
      }
    };
  });*/
};

export const getStopsServices = async () => {
  const response = await apiClient.post('/user/stops/getServices', {
    get: {}
  });
  if (!response.data) return response;
  return response.data.results;
};

const coordinatesZoneArrayToObject = zone => {
  if (!zone.geometry || !zone.geometry.coordinates)
    return {
      ...zone,
      show: true,
      open: false
    };
  let formattedCoordinates = [];
  let type = zone.geometry.type;
  switch (zone.geometry.type) {
    case GeometryTypes.Polygon:
      formattedCoordinates = [
        zone.geometry.coordinates.map(coordinate => {
          return coordinate.map(c => {
            return { lat: c[1], lng: c[0] };
          });
        })
      ];
      type = GeometryTypes.MultiPolygon;
      break;
    case GeometryTypes.MultiPolygon:
      formattedCoordinates = zone.geometry.coordinates.map(polygon => {
        return polygon.map(coordinate => {
          return coordinate.map(c => {
            return { lat: c[1], lng: c[0] };
          });
        });
      });
      break;
  }
  return {
    ...zone,
    geometry: {
      ...zone.geometry,
      type,
      coordinates: formattedCoordinates
    },
    show: true,
    open: false
  };
};
const coordinatesZoneObjectToArray = zone => {
  if (!zone.geometry || !zone.geometry.coordinates) return zone;
  let coordinates = JSON.parse(JSON.stringify(zone.geometry.coordinates));
  let formattedCoordinates = [];
  //Set last point equal to first if not is equal
  switch (zone.geometry.type) {
    case GeometryTypes.Polygon:
      if (
        JSON.stringify(coordinates[0][0]) !==
        JSON.stringify(coordinates[0][coordinates[0].length - 1])
      ) {
        coordinates[0].push(coordinates[0][0]);
      }
      break;
    case GeometryTypes.MultiPolygon:
      coordinates.forEach((c, index) => {
        if (JSON.stringify(c[0][0]) !== JSON.stringify(c[0][c[0].length - 1])) {
          coordinates[index][0].push(c[0][0]);
        }
      });
  }

  switch (zone.geometry.type) {
    case GeometryTypes.Polygon:
      formattedCoordinates = coordinates.map(coordinate => {
        return coordinate.map(c => {
          return [c.lng, c.lat];
        });
      });
      break;
    case GeometryTypes.MultiPolygon:
      formattedCoordinates = coordinates.map(polygon => {
        return polygon.map(coordinate => {
          return coordinate.map(c => {
            return [c.lng, c.lat];
          });
        });
      });
      break;
  }
  return {
    ...zone,
    geometry: {
      ...zone.geometry,
      coordinates: formattedCoordinates
    }
  };
};

export const zonesCreate = async data => {
  const create = coordinatesZoneObjectToArray(JSON.parse(JSON.stringify({geometry: data.geometry})));
  /*create.geometry.coordinates[0] = create.geometry.coordinates[0].map(
    coordinate => {
      return [coordinate.lng, coordinate.lat];
    }
  );*/
  const updateFields = [
    'info',
    'name',
    'type',
    //'thresholds',
    'status',
    'driver',
    //'services',
    //'postalCodes',
    'priority',
    'temporary',
    'dateRange',
    //'actions'
  ];
  updateFields.forEach(field => {
    if (Object.prototype.hasOwnProperty.call(data, field) && data[field]) {
      create[field] = JSON.parse(JSON.stringify(data[field]));
    }
  });
  const response = await apiClient.post('/user/zones/create', {
    create
  });
  if (!response.data) return response;
  if (!response.data.created) return response.data;
  return coordinatesZoneArrayToObject(response.data.created);
};

export const zonesUpdate = async (
  id,
  data,
  updateFields = [
    'info',
    'name',
    'type',
    //'thresholds',
    'status',
    'driver',
    'temporary',
    'dateRange',
    //'services',
    //'postalCodes',
    'priority',
    //'actions',
    'geometry'
  ]
) => {
  const update = {};
  console.log('zonesUpdate', data);
  if (updateFields.includes('geometry') && data.geometry) {
    update.geometry = coordinatesZoneObjectToArray(
      JSON.parse(JSON.stringify(data))
    ).geometry;
    /*update.geometry.coordinates[0] = data.geometry.coordinates[0].map(
      coordinate => {
        return [coordinate.lng, coordinate.lat];
      }
    );*/
  }
  updateFields.forEach(field => {
    if (field !== 'geometry' && data[field]) {
      update[field] = data[field];
    }
  });
  console.log('updateFields', updateFields);
  console.log('update', update);
  const response = await apiClient.post('/user/zones/update', {
    find: {
      query: 'id',
      id
    },
    set: update
  });
  if (!response.data) return response;
  return response.data;
};
export const zonesUpdateStatus = async (id, status) => {
  const update = {
    status: {
      code: status,
      at: moment().format('YYYY-MM-DDTHH:mm:ssZ')
    }
  };

  const response = await apiClient.post('/user/zones/update', {
    find: {
      query: 'id',
      id
    },
    set: update
  });
  if (!response.data) return response;
  return response.data;
};

export const zonesDelete = async code => {
  const data = {
    delete: {
      query: 'code',
      code
    }
  };
  const response = await apiClient.post('/user/zones/delete', data);
  if (!response.data) return response;
  return response.data;
};

export const driversFindAll = async () => {
  const response = await apiClient.post('/user/drivers/find', {
    find: {
      query: 'all'
    }
  });
  if (!response.data) return response;
  return response.data.found;
};
