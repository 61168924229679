import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingScreen from '../../componentsGLS/LoadingScreen';
const LoadingPage = () => {
  const { t } = useTranslation();

  return (
    <LoadingScreen />
  );
};

export default LoadingPage;
