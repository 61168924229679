import {useTranslation} from 'react-i18next';
import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, Row} from 'react-bootstrap';

const ErrorPage = props => {

  const { t} = useTranslation();
  const {code} = props;
  const [data, setData] = useState({});

  useEffect(() => {
    const newData = {};
    switch (code) {
      case 401:
        newData.title = 'Error401Title';
        newData.text = 'Error401Text';
        newData.link = 'https://www.gls-spain.es/es/';
        newData.textLink = 'Error401Link';
        break;
      case 404:
        newData.title = 'Error404Title';
        newData.text = 'Error404Text';
        newData.link = '/';
        newData.textLink = 'Error404Link';
        break;
      //case 500:
      default:
        newData.title = 'Error500Title';
        newData.text = 'Error500Text';
    }
    setData(newData);
  }, []);



  return (
    <>
      <Row className={'justify-content-center m-auto  align-items-center vh-100'}>
        <Col lg={4} className={'align-middle'}>
          <Card className={'align-middle text-center'}>
            <CardBody>
              <h1 className={'fw-bold text-primary'} style={{fontSize: '6em'}}>{code}</h1>
              <p className={'fw-bold'}>{t(data.title)}</p>
              <div className={'bg-gray mb-2'} style={{height: '1px'}}></div>
              <p className={'fw-light fs-9'}>{t(data.text)}</p>
              {data.link &&
                <Button className={'rounded-pill'} variant={'outline-primary'} href={data.link} >{t(data.textLink)}</Button>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default  ErrorPage;
