import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { AdvancedMarker, Pin } from '@vis.gl/react-google-maps';

const StopMarker = props => {
  const { stop, setMarkerRef } = props;
  const ref = useCallback(
    marker => setMarkerRef(marker, stop.id),
    [setMarkerRef, stop.id]
  );
  return (
    <AdvancedMarker position={stop.address.location.coordinates} ref={ref}>
      <Pin background={'#051ab1'} glyphColor={'#ffce00'} borderColor={'#fff'} />
    </AdvancedMarker>
  );
};

StopMarker.propTypes = {
  t: PropTypes.func,
  stop: PropTypes.any,
  setMarkerRef: PropTypes.func
};

export default withTranslation()(StopMarker);
