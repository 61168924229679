export const polygonOptions = {
  fillColor: 'black',
  fillOpacity: 0.25,
  strokeColor: 'black',
  strokeOpacity: 1,
  strokeWeight: 2,
  editable: false,
  draggable: false
};
export const postalCodeOptions = {
  fillColor: '#ffffff00',
  fillOpacity: 0.4,
  strokeColor: 'black',
  strokeOpacity: 1,
  strokeWeight: 2,
  editable: false,
  draggable: false
};

export const PolygonTypes = {
  ShipmentCode: 'ShipmentCode',
  Zone: 'Zone'
};

export const GeometryTypes = {
  Polygon: 'Polygon',
  MultiPolygon: 'MultiPolygon'
};
