import { useEffect, useRef } from 'react';

export const objectChange = (e, originalData, setOriginalData) => {
  const { name, value } = e.target;
  const newData = { ...originalData, [name]: value };
  setOriginalData(newData);
};

export const isJsonEmpty = obj => {
  return Object.keys(obj).length === 0;
};

export const getFPEType = stringDate => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const date = new Date(stringDate);
  if (date < today) {
    return -1;
  } else if (date > today) {
    return 1;
  }
  return 0;
};

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
