import { Button, ToggleButton } from 'react-bootstrap';
import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { MapStates } from '../dataGLS/CloserModes';
import Select from 'react-select';
import { MapContext } from '../providers/mapProvider';
import { GeometryTypes } from '../dataGLS/polygon';
import PropTypes from 'prop-types';

const MapToolbar = props => {
  const { t, drawingManagerRef, managerListener, mapState, setMapState } =
    props;
  const {
    shipmentAreas,
    selectedZone,
    setSelectedZone,
    setPolygons,
    history,
    historyUndo,
    historyRedo,
    historySetPresent,
    addPolygon
  } = useContext(MapContext);
  const google = window.google;
  const [postalCodes, setPostalCodes] = useState([]);

  const undo = () => {
    if (!historyUndo()) return;
    updatePolygonsZone();
    console.log('History Undo end', history.current);
  };
  const redo = () => {
    if (!historyRedo()) return;
    updatePolygonsZone();
    console.log('History Redo end', history.current);
  };

  const updatePolygonsZone = () => {
    setSelectedZone(prevVal => {
      const coordinates = history.current.present
        .filter(polygon => polygon.id.startsWith(selectedZone.id))
        .map(polygon => {
          console.log('History update map', polygon);
          return polygon.geometry.coordinates;
        });
      console.log('History updatePolygonsZone', { coordinates });
      return {
        ...prevVal,
        geometry: {
          ...prevVal.geometry,
          coordinates
        }
      };
    });
  };

  useEffect(() => {
    setPostalCodes(
      shipmentAreas.map(area => {
        return {
          label: area.code,
          value: area.code
        };
      })
    );
  }, [shipmentAreas]);

  useEffect(() => {
    if (mapState === MapStates.CREATE) {
      console.log('State create');
      drawingManagerRef.current.setDrawingMode(
        google.maps.drawing.OverlayType.POLYGON
      );
    } else {
      drawingManagerRef.current.setDrawingMode(null);
    }
  }, [mapState]);

  const toggleCreate = () => {
    if (mapState === MapStates.CREATE) {
      setMapState(MapStates.DEFAULT);
    } else {
      setMapState(MapStates.CREATE);
    }
  };

  const toggleDelete = () => {
    if (mapState === MapStates.DELETE) {
      setMapState(MapStates.DEFAULT);
    } else {
      setMapState(MapStates.DELETE);
    }
  };

  const createPCPolygon = selected => {
    const area = shipmentAreas.find(area => area.code === selected);
    const geometry = {
      ...area.polygon,
      type: GeometryTypes.MultiPolygon
    };
    const polygon = managerListener.current.createPolygonData(
      `${selectedZone.id}--${selectedZone?.geometry?.coordinates?.length || 0}`,
      geometry,
      area.polygon.coordinates
    );
    setPolygons(prevPolygons => {
      const present = [...prevPolygons, polygon];
      historySetPresent(present);
      return present;
    });
    addPolygon(area.polygon.coordinates);
  };

  return (
    <div className={'bg-white mb-3 p-1 shadow rounded-pill'}>
      <Button
        variant={'tertiary'}
        className={'mx-2 my-1 p-0 rounded-pill'}
        onClick={undo}
        disabled={history.current.past.length === 0}
      >
        <span className="material-symbols-outlined fs-9 p-2">undo</span>
      </Button>
      <Button
        variant={'tertiary'}
        className={'mx-2 my-1 p-0 rounded-pill'}
        onClick={redo}
        disabled={history.current.future.length === 0}
      >
        <span className="material-symbols-outlined fs-9 p-2">redo</span>
      </Button>
      <span
        className={'bg-secondary mx-2'}
        style={{
          paddingLeft: '1px',
          height: '24px',
          display: 'inline-block',
          verticalAlign: 'middle'
        }}
      ></span>
      <Select
        className={'d-inline-block rounded-pill border-none'}
        placeholder={t('C.P.')}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            background: state.isFocused ? '#E3E3E3' : 'white',
            borderColor: state.isFocused ? '#E3E3E3 !important' : 'white',
            borderRadius: 'var(--gls-border-radius-pill) !important',
            ':hover': {
              backgroundColor: '#E3E3E3',
              borderColor: '#E3E3E3'
            }
          }),
          placeholder: baseStyles => ({
            ...baseStyles,
            color: 'var(--gls-primary)',
            fontWeight: 'bold'
          }),
          singleValue: baseStyles => ({
            ...baseStyles,
            color: 'var(--gls-primary)',
            fontWeight: 'bold'
          }),
          indicatorSeparator: () => ({
            //...baseStyles,
            display: 'none'
          }),
          dropdownIndicator: baseStyles => ({
            ...baseStyles,
            color: 'var(--gls-primary) !important'
          })
        }}
        options={postalCodes}
        isSearchable={false}
        menuPlacement={'top'}
        value={''}
        onChange={selected => {
          createPCPolygon(selected.value);
        }}
      />
      <ToggleButton
        variant={`${mapState !== MapStates.CREATE ? 'outline-' : ''}primary`}
        checked={mapState === MapStates.CREATE}
        value="1"
        id={'toggle-map-create'}
        className={
          'border-white focus-visible cursor-pointer mx-2 my-1 p-0 rounded-pill bg-hover-primary text-hover-white'
        }
        onClick={toggleCreate}
      >
        <span className="material-symbols-outlined fs-9 p-2">polyline</span>
      </ToggleButton>
      <ToggleButton
        variant={`${
          mapState !== MapStates.DELETE ? 'outline-primary-' : ''
        }danger`}
        checked={mapState === MapStates.DELETE}
        value="1"
        id={'toggle-map-danger'}
        className={
          'border-white focus-visible cursor-pointer mx-2 my-1 p-0 rounded-pill bg-hover-danger text-hover-white'
        }
        onClick={toggleDelete}
      >
        <span className="material-symbols-outlined fs-9 p-2">delete</span>
      </ToggleButton>
    </div>
  );
};

MapToolbar.propTypes = {
  t: PropTypes.func,
  drawingManagerRef: PropTypes.any,
  managerListener: PropTypes.any,
  mapState: PropTypes.any,
  setMapState: PropTypes.func
};

export default withTranslation()(MapToolbar);
